import React, { useRef, useState } from "react";
import styled, { css } from "styled-components";
import { Button, Divider, IconButton } from "@mui/material";
import { Close, Hamburger, Logo } from "Icons";
import { COLORS } from "styles/Colors";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import { useNavigate } from "react-router-dom";

interface HeaderProps {
  isMobile?: boolean;
  language?: string;
  onHideFooter?: () => void;
}

const Header = ({ isMobile = false, language, onHideFooter }: HeaderProps) => {
  const navigate = useNavigate();
  const [drawer, setDrawer] = useState(false);
  const refDrawer = useRef<HTMLDivElement>(null);

  const handleOpenDrawer = () => {
    setDrawer(true);
  };

  const handleCloseDrawer = () => {
    setDrawer(false);
  };

  const handleRegisterGtag = (label?: string) => {
    ReactGA.event({
      category: "Button",
      action: "Press Register Button",
      label: label ? label : "Register Button",
    });
  };

  return (
    <Container>
      <HeaderWrapper isMobile={isMobile}>
        {isMobile ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 20px",
            }}
          >
            <Logo
              width={66}
              height={20}
              onClick={() => {
                onHideFooter && onHideFooter();
                navigate("/");
              }}
              style={{ cursor: "pointer" }}
            />
            <div>
              <Button
                variant="contained"
                size="small"
                href={`https://app.doslash.io?language=${language}`}
                onClick={() => {
                  handleRegisterGtag("Main");
                  ReactPixel.trackCustom("START_TRIAL");
                }}
                sx={{
                  backgroundColor: `${COLORS.sub2}`,
                  borderRadius: "8px",
                  textTransform: "none",
                  fontSize: "13px",
                  fontWeight: 700,
                  lineHeight: "20px",
                  letterSpacing: "-0.25px",
                  padding: "10px 16px",
                }}
              >
                {language === "ko" ? "무료로 시작하기" : "Start for Free"}
              </Button>
              {language === "ko" ? (
                <IconButton
                  onClick={drawer ? handleCloseDrawer : handleOpenDrawer}
                  sx={{ padding: "0px", marginLeft: "6px" }}
                >
                  {drawer ? <Close /> : <Hamburger />}
                </IconButton>
              ) : (
                <div />
              )}
            </div>
          </div>
        ) : (
          <>
            <Logo
              width={84}
              height={60}
              onClick={() => {
                onHideFooter && onHideFooter();
                navigate("/");
              }}
              style={{ cursor: "pointer" }}
            />
            <div>
              {/* <Button
                  href="https://slash.ghost.io/"
                  target="_blank"
                  sx={{
                    color: COLORS.gray900,
                    fontSize: "16px",
                    fontWeight: 600,
                    margin: "0px 20px",
                  }}
                >
                  블로그
                </Button> */}
              <Button
                href="https://www.sigcrew.com/"
                target="_blank"
                sx={{
                  color: COLORS.gray900,
                  fontSize: "16px",
                  fontWeight: 600,
                  margin: "0px 20px",
                }}
              >
                {language === "ko" ? "회사소개" : "Company"}
              </Button>
              <Button
                onClick={() => {
                  onHideFooter && onHideFooter();
                  navigate("/pricing");
                }}
                sx={{
                  color: COLORS.gray900,
                  fontSize: "16px",
                  fontWeight: 600,
                  margin: "0px 20px",
                }}
              >
                {language === "ko" ? "가격" : "Pricing"}
              </Button>
              <Button
                onClick={() => {
                  onHideFooter && onHideFooter();
                  navigate("/believer");
                }}
                sx={{
                  color: COLORS.gray900,
                  fontSize: "16px",
                  fontWeight: 600,
                  margin: "0px 20px",
                }}
              >
                {language === "ko" ? "빌리버" : "Believer"}
              </Button>
              {/* <Button
                  href="https://www.sigcrew.com/8af51ae9-8b6f-44aa-988c-9055c5ba57b0"
                  target="_blank"
                  sx={{
                    color: COLORS.gray900,
                    fontSize: "16px",
                    fontWeight: 600,
                    margin: "0px 20px",
                  }}
                >
                  채용
                </Button> */}
            </div>
            <Button
              variant="contained"
              sx={{
                backgroundColor: `${COLORS.sub2}`,
                borderRadius: "8px",
                textTransform: "none",
                height: "40px",
              }}
              href={`https://app.doslash.io?language=${language}`}
              onClick={() => {
                handleRegisterGtag("Desktop Header");
                ReactPixel.trackCustom("START_TRIAL");
              }}
            >
              <HeaderButtonText>
                {language === "ko" ? "무료로 시작하기" : "Start for Free"}
              </HeaderButtonText>
            </Button>
          </>
        )}
      </HeaderWrapper>
      {isMobile && (
        <DrawerWrapper drawer={drawer} ref={refDrawer}>
          <Divider sx={{ margin: "0px", borderColor: COLORS.gray200 }} />
          <DrawerContent>
            {/* <Button
              variant="text"
              sx={{
                width: "100%",
                color: `${COLORS.gray900}`,
                borderRadius: "8px",
                textTransform: "capitalize",
                padding: "10px",
              }}
              href="https://slash.ghost.io/"
              target="_blank"
            >
              <DrawerButtonText>블로그</DrawerButtonText>
            </Button> */}
            <Button
              variant="text"
              sx={{
                width: "100%",
                color: `${COLORS.gray900}`,
                borderRadius: "8px",
                textTransform: "capitalize",
                padding: "10px",
              }}
              href="https://www.sigcrew.com/"
              target="_blank"
            >
              <DrawerButtonText>회사소개</DrawerButtonText>
            </Button>
            <Button
              onClick={() => {
                onHideFooter && onHideFooter();
                navigate("/pricing");
                setDrawer(false);
              }}
              sx={{
                color: COLORS.gray900,
                fontSize: "16px",
                fontWeight: 600,
                margin: "0px 20px",
              }}
            >
              {language === "ko" ? "가격" : "Pricing"}
            </Button>
            <Button
              onClick={() => {
                onHideFooter && onHideFooter();
                navigate("/believer");
                setDrawer(false);
              }}
              sx={{
                color: COLORS.gray900,
                fontSize: "16px",
                fontWeight: 600,
                margin: "0px 20px",
              }}
            >
              {language === "ko" ? "빌리버" : "Believer"}
            </Button>
            {/* <Button
              variant="text"
              sx={{
                width: "100%",
                color: `${COLORS.gray900}`,
                borderRadius: "8px",
                textTransform: "capitalize",
                padding: "10px",
              }}
              href="https://www.sigcrew.com/8af51ae9-8b6f-44aa-988c-9055c5ba57b0"
              target="_blank"
            >
              <DrawerButtonText>채용</DrawerButtonText>
            </Button> */}
            <Button
              variant="contained"
              sx={{
                backgroundColor: `${COLORS.sub2}`,
                borderRadius: "8px",
                textTransform: "capitalize",
                padding: "10px 16px",
                margin: "12px 0px",
              }}
              href={`https://app.doslash.io?language=${language}`}
              onClick={() => {
                handleRegisterGtag("Mobile Header");
                ReactPixel.trackCustom("START_TRIAL");
              }}
            >
              <HeaderButtonText>무료로 시작하기</HeaderButtonText>
            </Button>
          </DrawerContent>
        </DrawerWrapper>
      )}
    </Container>
  );
};

export default Header;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 500;
  height: 72px;
`;

const HeaderWrapper = styled.div<{ isMobile?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 500;
  backdrop-filter: blur(10px);
  height: 72px;
`;

const HeaderButtonText = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
`;

const DrawerWrapper = styled.div<{ drawer?: boolean }>`
  z-index: 10;
  width: 100%;
  position: absolute;
  top: -250px;
  box-shadow: 0px 4px 16px 0px rgba(45, 106, 223, 0.08);
  transition: all ease 0.7s;
  ${(props) =>
    props.drawer &&
    css`
      transform: translateY(322px);
    `}
`;

const DrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0px;
  background-color: #ffffff80;
  backdrop-filter: blur(10px);
`;

const DrawerButtonText = styled.div`
  color: var(--Gray_900, #1f2023);
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: -0.25px;
`;
