import { Button, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { COLORS } from "styles/Colors";
import * as Icons from "Icons";
import PricingPhilosophy from "components/PricingPhilosophy";
import FooterStartButton from "components/FooterStartButton";
import { Fade } from "react-awesome-reveal";
import { useLocation, useNavigate } from "react-router-dom";

interface PricingProps {
  isBigDesktop?: boolean;
  isDesktop?: boolean;
  isTablet?: boolean;
  isSmallTablet?: boolean;
  isMobile?: boolean;
  language?: string;
}

const Pricing = ({
  isBigDesktop,
  isDesktop,
  isTablet,
  isSmallTablet,
  isMobile,
  language,
}: PricingProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [render, setRender] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setRender(true);
  }, [location]);

  const CoreFeature = [
    {
      title: language === "ko" ? "실행" : "Action",
      features:
        language === "ko"
          ? [
              "제한 없이 태스크 추가",
              "타임블로킹",
              "캘린더 일정 생성 · 수정",
              "반복 업무, 반복 일정",
              "메모",
              "빠른 입력",
              "태스크 그루핑",
              "통계",
            ]
          : [
              "Add tasks without limits",
              "Time-blocking",
              "Create and edit calendar events",
              "Recurring tasks and events",
              "Memos",
              "Quick input",
              "Task grouping",
              "Statistics",
            ],
    },
  ];

  const ProFeature = [
    // {
    //   title: language === "ko" ? "목표" : "Goal",
    //   status: language === "ko" ? "준비중" : "Coming soon",
    //   features:
    //     language === "ko"
    //       ? ["월간 목표", "목표 리마인더"]
    //       : ["Monthly goals", "Goal reminders"],
    // },
    {
      title: language === "ko" ? "계획" : "Plan",
      status: language === "ko" ? "현재 무료" : "Currently free",
      features:
        language === "ko"
          ? ["프로젝트", "프로젝트 타임블로킹", "루틴"]
          : ["Projects", "Project time-blocking", "Routines"],
    },
    {
      title: language === "ko" ? "회고" : "Reflection",
      status: language === "ko" ? "현재 무료" : "Currently free",
      features:
        language === "ko"
          ? ["데일리 회고", "지난 회고 다시 보기"]
          : ["Daily reflection", "Review past reflections"],
    },
  ];

  const BelieverFeature = [
    {
      title: "Early Access",
      features:
        language === "ko"
          ? ["새로운 기능 우선 출시"]
          : ["Early Access to New Features"],
      // features:
      //   language === "ko"
      //     ? ["새로운 기능 우선 출시", "목표 리마인더"]
      //     : ["Early Access to New Features", "Goal reminders"],
    },
    {
      title: "Contributions",
      features:
        language === "ko"
          ? ["로드맵 확인", "신규 기능 투표"]
          : ["View roadmap", "Vote on new features"],
    },
    {
      title: "100",
      features:
        language === "ko"
          ? ["베타 참여 고객을 위한 100개 멤버십 한정 판매"]
          : ["Limited sale of 100 memberships for beta participants"],
    },
  ];

  return (
    <>
      {render && (
        <Container isMobile={isMobile}>
          <PricingHeader>
            <Fade direction="up" triggerOnce={true} duration={1500}>
              <PricingHeaderIcon isMobile={isMobile}>
                {language === "ko" ? "💰 가격" : "💰 Pricing"}
              </PricingHeaderIcon>
              <PricingHeaderTitle isMobile={isMobile}>
                <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
                  Everybody
                </span>
                &nbsp;can be effective
              </PricingHeaderTitle>
              <PricingHeaderSubtitle isMobile={isMobile}>
                {language === "ko"
                  ? `누구나 자신의 비전을 현실화할 능력을 가질 수 있어야 합니다\n이를 위해 SLASH의 코어 기능은 영원히 무료로 제공됩니다`
                  : `Everyone should have the power to bring their vision to life\nTo achieve this, SLASH’s core features will always be provided for free`}
              </PricingHeaderSubtitle>
            </Fade>
          </PricingHeader>
          <Fade
            direction="up"
            triggerOnce={true}
            duration={1500}
            style={{ width: "100%" }}
          >
            <PricingContent isMobile={isMobile}>
              <PricingContentCard isMobile={isMobile}>
                <PricingContentCardTitle isMobile={isMobile}>
                  Core
                </PricingContentCardTitle>
                <PricingContentPrice isMobile={isMobile}>
                  Free
                </PricingContentPrice>
                <Button
                  variant="contained"
                  sx={{
                    width: "100%",
                    height: isMobile ? "40px" : "48px",
                    backgroundColor: COLORS.brand1,
                    borderRadius: isMobile ? "8px" : "9.6px",
                    boxShadow: "none",
                    margin: isMobile ? "24px 0px" : "65px 0px 28px 0px",
                    padding: isMobile ? "10px 16px" : "12px 19.2px",
                    fontSize: isMobile ? "13px" : "16px",
                    fontWeight: 700,
                    lineHeight: isMobile ? "20px" : "24px",
                    letterSpacing: "-0.25px",
                    textTransform: "none",
                  }}
                >
                  {language === "ko" ? "시작하기" : "Start"}
                </Button>
                <FeatureWrapper>
                  <FeatureDescription isMobile={isMobile}>
                    {language === "ko" ? "포함하는 기능" : "Included Features"}
                  </FeatureDescription>
                  {CoreFeature.map((feature, idx) => (
                    <FeatureListWrapper key={idx}>
                      <FeatureListTitle isMobile={isMobile}>
                        {feature.title}
                      </FeatureListTitle>
                      {feature.features.map((feature, idx) => (
                        <FeatureListContent key={idx}>
                          <Icons.Check
                            width={isMobile ? 24 : 28}
                            height={isMobile ? 24 : 28}
                            style={{
                              marginRight: 4.8,
                              minWidth: isMobile ? 24 : 28,
                            }}
                          />
                          <span style={{ marginTop: isMobile ? 4 : 6 }}>
                            {feature}
                          </span>
                        </FeatureListContent>
                      ))}
                    </FeatureListWrapper>
                  ))}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: COLORS.gray100,
                      borderRadius: isMobile ? "12px" : "14.4px",
                      padding: isMobile ? "12px" : "14.4px",
                      fontSize: isMobile ? "12px" : "13px",
                      lineHeight: isMobile ? "18px" : "20px",
                      letterSpacing: "-0.25px",
                      marginTop: isMobile ? "24px" : "28px",
                    }}
                  >
                    {language === "ko"
                      ? "Pro 출시 전까지 Pro의 모든 기능이 무료로 제공돼요!"
                      : "Until Pro is officially launched, all Pro features are available for free"}
                  </div>
                </FeatureWrapper>
              </PricingContentCard>
              <PricingContentCard isMobile={isMobile}>
                <PricingContentCardTitle isMobile={isMobile}>
                  Pro
                </PricingContentCardTitle>
                <PricingContentPrice
                  isMobile={isMobile}
                  style={{ color: COLORS.gray600 }}
                >
                  {language === "ko" ? "$10 / 월" : "$10 / Month"}
                </PricingContentPrice>
                <Button
                  variant="contained"
                  disabled
                  sx={{
                    width: "100%",
                    height: isMobile ? "40px" : "48px",
                    backgroundColor: COLORS.brand1,
                    borderRadius: isMobile ? "8px" : "9.6px",
                    boxShadow: "none",
                    margin: isMobile ? "24px 0px" : "65px 0px 28px 0px",
                    padding: isMobile ? "10px 16px" : "12px 19.2px",
                    fontSize: isMobile ? "13px" : "16px",
                    fontWeight: 700,
                    lineHeight: isMobile ? "20px" : "24px",
                    letterSpacing: "-0.25px",
                    textTransform: "none",
                  }}
                >
                  {language === "ko" ? "준비중" : "Coming soon"}
                </Button>
                <FeatureWrapper>
                  <FeatureDescription isMobile={isMobile}>
                    {language === "ko"
                      ? "Core의 모든 기능과"
                      : "All Core Features Plus"}
                  </FeatureDescription>
                  {ProFeature.map((feature, idx) => (
                    <FeatureListWrapper
                      key={idx}
                      style={
                        idx === 2
                          ? {
                              marginTop: "20px",
                            }
                          : {}
                      }
                    >
                      <FeatureListTitle isMobile={isMobile}>
                        <div>{feature.title}</div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            height: isMobile ? "19px" : "22.8px",
                            backgroundColor:
                              idx === 0 ? COLORS.gray100 : COLORS.sub3,
                            borderRadius: isMobile ? "6px" : "7.2px",
                            color: idx === 0 ? COLORS.gray500 : COLORS.brand1,
                            fontSize: isMobile ? "10px" : "12px",
                            marginLeft: isMobile ? "6px" : "7.2px",
                            padding: isMobile ? "2px 6px" : "2.4px 7.2px",
                          }}
                        >
                          {feature.status}
                        </div>
                      </FeatureListTitle>
                      {feature.features.map((feature, idx) => (
                        <FeatureListContent key={idx}>
                          <Icons.Check
                            width={isMobile ? 24 : 28}
                            height={isMobile ? 24 : 28}
                            style={{
                              marginRight: 4.8,
                              minWidth: isMobile ? 24 : 28,
                            }}
                          />
                          <span style={{ marginTop: isMobile ? 4 : 6 }}>
                            {feature}
                          </span>
                        </FeatureListContent>
                      ))}
                      {idx === 0 && <Divider sx={{ margin: "20px 0px" }} />}
                    </FeatureListWrapper>
                  ))}
                </FeatureWrapper>
              </PricingContentCard>
              <PricingContentCard isMobile={isMobile}>
                <PricingContentCardTitle isMobile={isMobile}>
                  Believer
                </PricingContentCardTitle>
                <PricingContentPrice isMobile={isMobile}>
                  {language === "ko" ? "$200 / 5년" : "$200 / 5 Years"}
                </PricingContentPrice>
                <PricingContentSubPrice isMobile={isMobile}>
                  <div
                    style={{
                      backgroundColor: COLORS.negative1,
                      borderRadius: isMobile ? 8 : 10,
                      color: COLORS.white,
                      fontSize: isMobile ? 12 : 14,
                      lineHeight: isMobile ? "18px" : "20px",
                      letterSpacing: isMobile ? -0.24 : -0.3,
                      marginRight: 10,
                      padding: isMobile ? "2px 8px" : "4px 10px",
                    }}
                  >
                    -67%
                  </div>
                  <div>{language === "ko" ? "$3.3 / 월" : "$3.3 / Month"}</div>
                </PricingContentSubPrice>
                <Button
                  variant="outlined"
                  onClick={() => navigate("/believer")}
                  sx={{
                    width: "100%",
                    height: "48px",
                    border: `1.2px solid ${COLORS.brand1}`,
                    borderRadius: "9.6px",
                    boxShadow: "none",
                    color: COLORS.brand1,
                    margin: "28px 0px 28px 0px",
                    padding: "12px 19.2px",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "24px",
                    letterSpacing: "-0.25px",
                    textTransform: "none",
                  }}
                >
                  {language === "ko" ? "더 알아보기" : "Learn More"}
                </Button>
                <FeatureWrapper>
                  <FeatureDescription isMobile={isMobile}>
                    {language === "ko"
                      ? "Pro의 모든 기능과"
                      : "All Pro Features Plus"}
                  </FeatureDescription>
                  {BelieverFeature.map((feature, idx) => (
                    <FeatureListWrapper
                      key={idx}
                      style={idx === 0 ? {} : { marginTop: "20px" }}
                    >
                      <FeatureListTitle isMobile={isMobile}>
                        <div>{feature.title}</div>
                      </FeatureListTitle>
                      {feature.features.map((feature, idx) => (
                        <FeatureListContent key={idx}>
                          <Icons.Check
                            width={isMobile ? 24 : 28}
                            height={isMobile ? 24 : 28}
                            style={{
                              marginRight: 4.8,
                              minWidth: isMobile ? 24 : 28,
                            }}
                          />
                          <span style={{ marginTop: isMobile ? 4 : 6 }}>
                            {feature}
                          </span>
                        </FeatureListContent>
                      ))}
                    </FeatureListWrapper>
                  ))}
                </FeatureWrapper>
              </PricingContentCard>
            </PricingContent>
          </Fade>
          <Fade
            direction="up"
            triggerOnce={true}
            duration={1500}
            style={{ width: "100%" }}
          >
            <PricingPhilosophy isMobile={isMobile} language={language} />
          </Fade>
          <Fade
            direction="up"
            triggerOnce={true}
            duration={1500}
            style={{ width: "100%" }}
          >
            <FooterStartButton
              isTablet={isTablet}
              isSmallTablet={isSmallTablet}
              isMobile={isMobile}
              language={language}
            />
          </Fade>
        </Container>
      )}
    </>
  );
};

export default Pricing;

const Container = styled.div<{ isMobile?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 175px;

  ${(props) =>
    props.isMobile &&
    css`
      margin-top: 36px;
    `}
`;

const PricingHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PricingHeaderIcon = styled.div<{ isMobile?: boolean }>`
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.sub3};
  border-radius: 164.948px;
  color: ${COLORS.brand1};
  font-size: 21.443px;
  font-weight: 700;
  line-height: 32.99px;
  letter-spacing: -0.412px;
  padding: 3.299px 13.196px;

  ${(props) =>
    props.isMobile &&
    css`
      font-size: 13px;
      line-height: 20px;
      letter-spacing: -0.25px;
      padding: 2px 8px;
    `}
`;

const PricingHeaderTitle = styled.div<{ isMobile?: boolean }>`
  font-size: 54px;
  font-weight: 700;
  line-height: 150%; /* 81px */
  letter-spacing: -0.25px;
  margin: 40px 0px 12px 0px;

  ${(props) =>
    props.isMobile &&
    css`
      font-size: 24px;
      line-height: 36px;
      letter-spacing: -0.25px;
      margin: 48px 0px 8px 0px;
    `}
`;

const PricingHeaderSubtitle = styled.div<{ isMobile?: boolean }>`
  color: ${COLORS.gray500};
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 150%; /* 30px */
  white-space: pre-line;

  ${(props) =>
    props.isMobile &&
    css`
      font-size: 13px;
      line-height: 20px;
      letter-spacing: -0.25px;
    `}
`;

const PricingContent = styled.div<{ isMobile?: boolean }>`
  width: 100%;
  height: 763px;
  display: flex;
  justify-content: space-around;
  margin: 96px 0px 240px 0px;
  padding: 0px 100px;
  box-sizing: border-box;

  ${(props) =>
    props.isMobile &&
    css`
      height: 100%;
      flex-direction: column;
      align-items: center;
      margin: 60px 0px 110px 0px;
      padding: 0px;
    `}
`;

const PricingContentCard = styled.div<{ isMobile?: boolean }>`
  width: 30%;
  height: 100%;
  background: ${COLORS.white};
  border: 1.2px solid ${COLORS.gray100};
  border-radius: 24px;
  box-shadow: 0px 4.8px 19.2px 0px rgba(45, 106, 223, 0.08);
  padding: 28.8px;
  box-sizing: border-box;

  ${(props) =>
    props.isMobile &&
    css`
      width: 90%;
      :not(:last-child) {
        margin-bottom: 32px;
      }
    `}
`;

const PricingContentCardTitle = styled.div<{ isMobile?: boolean }>`
  font-size: 24px;
  font-weight: 600;
  line-height: 28px; /* 116.667% */
  letter-spacing: -0.3px;

  ${(props) =>
    props.isMobile &&
    css`
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.25px;
    `}
`;

const PricingContentPrice = styled.div<{ isMobile?: boolean }>`
  text-align: right;
  font-size: 38px;
  font-weight: 700;
  line-height: 42px; /* 110.526% */
  letter-spacing: -0.3px;
  margin-top: 12px;

  ${(props) =>
    props.isMobile &&
    css`
      font-size: 32px;
      line-height: 36px;
      letter-spacing: -0.25px;
      margin-top: 8px;
    `}
`;

const PricingContentSubPrice = styled.div<{ isMobile?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px; /* 150% */
  letter-spacing: -0.25px;
  margin-top: 8px;

  ${(props) =>
    props.isMobile &&
    css`
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.25px;
    `}
`;

const FeatureWrapper = styled.div``;

const FeatureDescription = styled.div<{ isMobile?: boolean }>`
  color: ${COLORS.gray600};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.25px;
  margin-bottom: 14.4px;

  ${(props) =>
    props.isMobile &&
    css`
      font-size: 13px;
      line-height: 20px;
      letter-spacing: -0.25px;
    `}
`;

const FeatureListWrapper = styled.div``;

const FeatureListTitle = styled.div<{ isMobile?: boolean }>`
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px; /* 150% */
  letter-spacing: -0.3px;
  margin-bottom: 10px;

  ${(props) =>
    props.isMobile &&
    css`
      font-size: 20px;
      line-height: 30px;
      letter-spacing: -0.25px;
    `}
`;

const FeatureListContent = styled.div<{ isMobile?: boolean }>`
  display: flex;
  align-items: flex-start;
  &:not(:last-child) {
    margin-bottom: 9.6px;
  }
`;
